// ion-app {}

ion-modal {
  --backdrop-opacity: 0.9 !important;

  ion-header ion-toolbar:first-of-type,
  ion-header ion-toolbar {
    padding-top: 0;
  }

  ion-footer ion-toolbar {
    padding-bottom: var(--ion-safe-area-bottom, 0);
  }
}

ion-radio {
  --color-checked: var(--shelfy-filter-button-fill-active);
}

ion-button {
  &.ios.button.button-clear {
    --border-radius: var(--shelfy-btn-border-radius);
    --border-color: var(--shelfy-picker-button-lower-prio);
    --color: var(--shelfy-picker-button-lower-prio-text);
    font-size: 11px;

    &.shelfy-secondary-btn {
      --background: var(--shelfy-picker-button-lower-prio);
    }
  }

  &.ios.button.button-outline {
    --border-radius: var(--shelfy-btn-border-radius);
    --border-color: var(--shelfy-picker-button-lower-prio);
    --color: var(--shelfy-picker-button-lower-prio-text);
    font-size: 11px;

    &.shelfy-primary-btn {
      --color: var(--shelfy-picker-button-highlight);
      --border-color: var(--shelfy-picker-button-highlight);
    }
  }

  &.ios.button.button-solid {
    --border-radius: var(--shelfy-btn-border-radius);
    --border-color: var(--shelfy-picker-button-lower-prio);
    --color: var(--shelfy-picker-button-lower-prio-text);
    font-size: 11px;

    &.shelfy-primary-btn {
      --color: var(--shelfy-picker-button-highlight);
      --border-color: var(--shelfy-picker-button-highlight);
    }

    &.choose-button {
      --background: var(--shelfy-cuppertino-button-text-active);
    }
  }
}

.pointer {
  cursor: pointer;
}

.primary-button {
  font-weight: bold !important;
}

.secondary-button {
  font-weight: normal !important;
}

.order-completion-alert {
  --backdrop-opacity: 67%;
  --width: calc(100vw - 30px);
  --min-width: 200px;
  --max-width: 300px;

  .alert-wrapper {
    border: 2px solid var(--ion-color-primary);
  }

  .alert-title {
    color: var(--ion-color-primary);
    margin: 8px 0;
  }

  .alert-sub-title,
  .alert-message>p {
    padding: 6px 0;
    line-height: 125%;
    font-size: 14px;
    color: var(--ion-color-step-600, #{$ion-color-grey});

    ion-icon {
      font-size: 20px;
      vertical-align: middle !important;
      color: #666 !important;
    }
  }

  .alert-button-group {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .shopping-cart-alert-button {
    height: 36px;
    font-size: 15px;
    --padding-start: 20px;
    --padding-end: 20px;
    border-radius: 64px;
    margin-bottom: 20px;
    min-width: 10%;
    max-width: 33%;
  }

  .back-button.alert-button.sc-ion-alert-ios {
    border: 1px solid var(--ion-color-primary);
    border: none;
    color: var(--ion-color-primary);
    background: transparent !important;
  }

  // .alert-button.sc-ion-alert-ios

  .continue-button {
    color: white;
    background: var(--ion-color-primary);
  }
}

ion-icon.grey-icon {
  color: #666;
}

.no-cursor {
  cursor: default;
}

.currency-container {
  position: relative;
  width: auto;
  height: auto;
}

.strike-through {
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  height: 0px;
  border-top: 1px solid #666;
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  ion-modal {
    --width: 100% !important;
    --height: 100% !important;
    --border-radius: 0 !important;
  }
}

// bottom sheet (Regale, Ansichten, Hacks)
.shelves-scenes-hacks-sheet {
  .pane {
    padding-top: 0px;
    box-shadow: var(--cupertino-pane-shadow, 0 4px 16px rgba(0, 0, 0, 0.3));

    left: 0px;
    right: 0px;
    width: 100%;
    max-width: 100%;

    .draggable {
      top: -12px;

      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      height: 40px;
      height: 12px;
      z-index: 0;

      bottom: initial;
      padding: 0;

      .move {
        height: 12px;
        width: 90px;
        top: 0;
        background-color: var(--shelfy-designer-button-background);
        border-radius: 25px 25px 0 0;
      }
    }
  }
}

// desktop layout
@media only screen and (min-width: 768px) {
  .shelves-scenes-hacks-sheet .pane {
    left: 0px;
    right: unset;
    width: calc(100% - 329px) !important;
    max-width: calc(100% - 329px);

    .cupertino-pane {
      height: 100%;
    }
  }
}

.product-list-sheet {
  .pane {
    right: 0;
    left: unset;
    width: 499px;
    --cupertino-pane-border-radius: 0;
    z-index: 100;
  }
}

ion-popover.select-popover {
  &::part(backdrop) {
    background-color: rgba(#000, 0.05);
  }
}


/* ----------------------------------------------
 * Generated by Animista on 2022-4-2 21:32:46
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2022-4-2 21:40:52
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation puff-out-center
 * ----------------------------------------
 */
@-webkit-keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

@keyframes puff-out-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    -webkit-filter: blur(4px);
    filter: blur(4px);
    opacity: 0;
  }
}

.header-ios {
  padding: 10px 8px 10px 18px;

  ion-toolbar {
    &:last-of-type {
      --border-width: 0;
    }
  }
}

ion-modal:not(.modal-sheet) {
  --border-radius: 10px;
  --border-radius: 0px;
  --background: transparent;
  --backdrop-opacity: var(--ion-backdrop-opacity);

  .modal-wrapper {
    // margin-top: 80px;
    background: none;
  }

  .header-ios {
    margin-top: 70px;
    border-radius: 10px 10px 0 0 !important;
    background-color: white;

    ion-toolbar {
      /* --background: transparent; // needed for black background in the header*/
      /* --color: #eee; */
      --color: #666666;
      font-weight: 300;
      --padding-start: 0px;
      --padding-end: 0px;
      --padding-top: 0px;
      --padding-bottom: 0px;

      &:last-of-type {
        --border-width: 0;
      }
    }
  }

  ion-content {
    border-radius: 0;
    background-color: white;
    --background: white;
    height: calc(100% - 120px);

    .list-ios {
      background: transparent !important;

      ion-item,
      .item {
        --background: transparent;
      }
    }
  }

  ion-footer {
    background: white;
  }

  &.app-menu {
    --background: var(--ion-background-color, #fff);

    ion-toolbar {
      --background: white;
    }
  }
}

ion-toolbar {
  --background: white;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
  ion-modal {
    --width: 95%;
    --height: 95%;
  }
}
