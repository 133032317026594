/* Ionic Variables and Theming. */
/* This is just a placeholder file For more info, please see: */
/* https://ionicframework.com/docs/theming/basics */

/* To quickly generate your own theme, check out the color generator */
/* https://ionicframework.com/docs/theming/color-generator */

$app-size-mobile-max: 576px;
$app-size-desktop-min: 768px;
/* from here split screen in shelf designer */
$app-size-tablet: 1024px;

$ion-color-primary: #399;
$ion-color-secondary: #c0003f;
$ion-color-grey: #666;

// Only for debugging stuff
$shelfy-test: red;

$shelfy-shopping-card-bar-background: #c0003f;
$shelfy-shopping-card-bar-button-hover: #eee;
$shelfy-shopping-card-bar-text: #fff;
$shelfy-shopping-card-background: #fff;
$shelfy-shopping-card-affiliate-background: #f5f5f5;
$shelfy-shopping-card-price-summary: #000;
$shelfy-shopping-card-price-buy: #777;
$shelfy-shopping-card-price-not-buy: #ccc;
$shelfy-shopping-card-icon-button: #c0003f;
$shelfy-shopping-card-icon-button-hover: #85143a;
$shelfy-shopping-card-affiliate-button-text: #fff;

$shelfy-cuppertino-button-background: #bce1e0;
$shelfy-cuppertino-button-background-active: #fff;
$shelfy-cuppertino-button-text: #666;
$shelfy-cuppertino-button-text-active: #399;
$shelfy-cuppertino-button-scroll: #fff;
$shelfy-cuppertino-button-scroll-text: #000;

$shelfy-picker-selected-item: #399;
$shelfy-picker-button-highlight: #399;
$shelfy-picker-button-lower-prio: #333;
$shelfy-picker-button-lower-prio-text: #000;
$shelfy-picker-image-background: #eee;
$shelfy-picker-item-name: #399;
$shelfy-picker-item-price: #666;
$shelfy-picker-item-price-sale: #888;
$shelfy-picker-item-price-sale-new: #be1d53;
$shelfy-picker-item-title-background: rgba(#000, 0.5);
$shelfy-picker-item-title-hover: rgba(#399, 0.7);
$shelfy-picker-item-title-hover-text: rgba(#333, 0.7);
$shelfy-picker-item-title-text: #fff;
$shelfy-picker-item-button-top: rgba(255 255 255 / 80%);

$shelfy-designs-border: #e2e2e2;
$shelfy-designs-description: #777;

$shelfy-designer-button-background: #399;
$shelfy-designer-button-background-icon: #399;
$shelfy-designer-button-background-icon-hover: #399;
$shelfy-designer-button-hover: #177;
$shelfy-designer-button-text: #fff;
$shelfy-designer-button-text-mobile: #399;

$shelfy-no-sceenes-found: #aaa;

$shelfy-filter-button-fill: #666;
$shelfy-filter-button-fill-active: #399;
$shelfy-filter-button-fill-active-hover: rgb(51, 153, 153, 0.7);
$shelfy-filter-button-fill-text: #fff;
$shelfy-filter-button-outline-active: #399;
$shelfy-filter-button-outline-text: #666;
$shelfy-filter-not-accordion: rgba(51, 153, 153, 0.2);
$shelfy-filter-warning: #c0003f;
$shelfy-filter-color-chip: rgba(0, 0, 0, 0.67);
$shelfy-filter-card: #399;
$shelfy-filter-segment-active: #fff;
$shelfy-filter-segment-active-text: #000;

$shelfy-item-fit-not: #fff;
$shelfy-item-fit-not-bg: rgba(190, 29, 83, 0.4);
$shelfy-item-fit-not-text: #be1d53;
$shelfy-item-fit-warning: #c04600;

$shelfy-menu: #666;
$shelfy-menu-link: #399;
$shelfy-menu-warning: #c0003f;

$shelfy-landing-cta: #c0003f;
$shelfy-landing-claim: #fff;
$shelfy-landing-fact: #399;
$shelfy-landing-cta-text: #fff;
$shelfy-landing-background: #fff;
$shelfy-landing-text: #000;
$shelfy-landing-hero-image: #008d7d;
$shelfy-landing-block: #fff;
$shelfy-landing-block-background: #399;
$shelfy-landing-be-creative: #333;
$shelfy-landing-testimonials: #e7f3f3;
$shelfy-landing-platforms-background: #fff;
$shelfy-landing-social: #399;
$shelfy-landing-social-cirlce: #fff;
$shelfy-landing-social-logo: #fff;
$shelfy-landing-footer: rgba(51, 153, 153, 0.56);
$shelfy-landing-footer-text: #236868;

$shelfy-header-toolbar: #666;
$shelfy-header-toolbar-subtitle: #399;
$shelfy-button-outline: #333;
$shelfy-hr: #eee;
$shelfy-button-only-text: #399;
$shelfy-dropdown: #399;
$shelfy-dropdown-border: #666;
$shelfy-h2: #399;
$shelfy-toggle-active: #399;
$shelfy-toggle-inactive: #000;
$shelfy-logoBG: #399;
$shelfy-current-app-version: grey;

@font-face {
  font-family: 'Montserrat-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat-Medium';
  font-style: normal;
  font-weight: weight;
  src: url('../assets/fonts/Montserrat-Medium.ttf');
}

@font-face {
  font-family: 'Montserrat-Bold';
  font-style: normal;
  font-weight: weight;
  src: url('../assets/fonts/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat-SemiBold';
  font-style: normal;
  font-weight: 500;
  src: url('../assets/fonts/Montserrat-SemiBold.ttf');
}

body,
html {
  // font-family: 'Montserrat-Regular' !important;
  --ion-default-font: 'Montserrat-Regular';
}

:root {
  --ion-backdrop-opacity: 0.9;

  --shelfy-test: #{$shelfy-test};

  --shelfy-shopping-card-bar-background: #{$shelfy-shopping-card-bar-background};
  --shelfy-shopping-card-bar-button-hover: #{$shelfy-shopping-card-bar-button-hover};
  --shelfy-shopping-card-bar-text: #{$shelfy-shopping-card-bar-text};
  --shelfy-shopping-card-background: #{$shelfy-shopping-card-background};
  --shelfy-shopping-card-affiliate-background: #{$shelfy-shopping-card-affiliate-background};
  --shelfy-shopping-card-price-summary: #{$shelfy-shopping-card-price-summary};
  --shelfy-shopping-card-price-buy: #{$shelfy-shopping-card-price-buy};
  --shelfy-shopping-card-price-not-buy: #{$shelfy-shopping-card-price-not-buy};
  --shelfy-shopping-card-icon-button: #{$shelfy-shopping-card-icon-button};
  --shelfy-shopping-card-icon-button-hover: #{$shelfy-shopping-card-icon-button-hover};
  --shelfy-shopping-card-affiliate-button-text: #{$shelfy-shopping-card-affiliate-button-text};
  --shelfy-shopping-card-affiliate-radius: 10px;

  --shelfy-cuppertino-button-background: #{$shelfy-cuppertino-button-background};
  --shelfy-cuppertino-button-background-active: #{$shelfy-cuppertino-button-background-active};
  --shelfy-cuppertino-button-text: #{$shelfy-cuppertino-button-text};
  --shelfy-cuppertino-button-text-active: #{$shelfy-cuppertino-button-text-active};
  --shelfy-cuppertino-button-scroll: #{$shelfy-cuppertino-button-scroll};
  --shelfy-cuppertino-button-scroll-text: #{$shelfy-cuppertino-button-scroll-text};

  --shelfy-no-sceenes-found: #{$shelfy-no-sceenes-found};

  --shelfy-picker-selected-item: #{$shelfy-picker-selected-item};
  --shelfy-picker-button-highlight: #{$shelfy-picker-button-highlight};
  --shelfy-picker-button-lower-prio: #{$shelfy-picker-button-lower-prio};
  --shelfy-picker-button-lower-prio-text: #{$shelfy-picker-button-lower-prio-text};
  --shelfy-picker-image-background: #{$shelfy-picker-image-background};
  --shelfy-picker-item-name: #{$shelfy-picker-item-name};
  --shelfy-picker-item-price: #{$shelfy-picker-item-price};
  --shelfy-picker-item-price-sale: #{$shelfy-picker-item-price-sale};
  --shelfy-picker-item-price-sale-new: #{$shelfy-picker-item-price-sale-new};
  --shelfy-picker-item-title-background: #{$shelfy-picker-item-title-background};
  --shelfy-picker-item-title-hover: #{$shelfy-picker-item-title-hover};
  --shelfy-picker-item-title-hover-text: #{$shelfy-picker-item-title-hover-text};
  --shelfy-picker-item-title-text: #{$shelfy-picker-item-title-text};
  --shelfy-picker-item-button-top: #{$shelfy-picker-item-button-top};

  --shelfy-designs-border: #{$shelfy-designs-border};
  --shelfy-designs-description: #{$shelfy-designs-description};

  --shelfy-designer-button-background: #{$shelfy-designer-button-background};
  --shelfy-designer-button-background-icon: #{$shelfy-designer-button-background-icon};
  --shelfy-designer-button-background-icon-hover: #{$shelfy-designer-button-background-icon-hover};
  --shelfy-designer-button-hover: #{$shelfy-designer-button-hover};
  --shelfy-designer-button-text: #{$shelfy-designer-button-text};
  --shelfy-designer-button-text-mobile: #{$shelfy-designer-button-text-mobile};

  --shelfy-filter-button-fill: #{$shelfy-filter-button-fill};
  --shelfy-filter-button-fill-active: #{$shelfy-filter-button-fill-active};
  --shelfy-filter-button-fill-active-hover: #{$shelfy-filter-button-fill-active-hover};
  --shelfy-filter-button-fill-text: #{$shelfy-filter-button-fill-text};
  --shelfy-filter-button-outline-active: #{$shelfy-filter-button-outline-active};
  --shelfy-filter-button-outline-text: #{$shelfy-filter-button-outline-text};
  --shelfy-filter-not-accordion: #{$shelfy-filter-not-accordion};
  --shelfy-filter-warning: #{$shelfy-filter-warning};
  --shelfy-filter-color-chip: #{$shelfy-filter-color-chip};
  --shelfy-filter-card: #{$shelfy-filter-card};
  --shelfy-filter-segment-active: #{$shelfy-filter-segment-active};
  --shelfy-filter-segment-active-text: #{$shelfy-filter-segment-active-text};

  --shelfy-item-fit-not: #{$shelfy-item-fit-not};
  --shelfy-item-fit-not-bg: #{$shelfy-item-fit-not-bg};
  --shelfy-item-fit-not-text: #{$shelfy-item-fit-not-text};
  --shelfy-item-fit-warning: #{$shelfy-item-fit-warning};

  --shelfy-menu: #{$shelfy-menu};
  --shelfy-menu-link: #{$shelfy-menu-link};
  --shelfy-menu-warning: #{$shelfy-menu-warning};

  --shelfy-landing-cta: #{$shelfy-landing-cta};
  --shelfy-landing-cta-text: #{$shelfy-landing-cta-text};
  --shelfy-landing-claim: #{$shelfy-landing-claim};
  --shelfy-landing-fact: #{$shelfy-landing-fact};
  --shelfy-landing-background: #{$shelfy-landing-background};
  --shelfy-landing-text: #{$shelfy-landing-text};
  --shelfy-landing-block: #{$shelfy-landing-block};
  --shelfy-landing-be-creative: #{$shelfy-landing-be-creative};
  --shelfy-landing-block-background: #{$shelfy-landing-block-background};
  --shelfy-landing-testimonials: #{$shelfy-landing-testimonials};
  --shelfy-landing-platforms-background: #{$shelfy-landing-platforms-background};
  --shelfy-landing-social: #{$shelfy-landing-social};
  --shelfy-landing-social-circle: #{$shelfy-landing-social-cirlce};
  --shelfy-landing-social-logo: #{$shelfy-landing-social-logo};
  --shelfy-landing-footer: #{$shelfy-landing-footer};
  --shelfy-landing-footer-text: #{$shelfy-landing-footer-text};

  --shelfy-header-toolbar: #{$shelfy-header-toolbar};
  --shelfy-header-toolbar-subtitle: #{$shelfy-header-toolbar-subtitle};
  --shelfy-button-outline: #{$shelfy-button-outline};
  --shelfy-hr: #{$shelfy-hr};
  --shelfy-button-only-text: #{$shelfy-button-only-text};
  --shelfy-dropdown: #{$shelfy-dropdown};
  --shelfy-dropdown-border: #{$shelfy-dropdown-border};
  --shelfy-h2: #{$shelfy-h2};
  --shelfy-toggle-active: #{$shelfy-toggle-active};
  --shelfy-toggle-inactive: #{$shelfy-toggle-inactive};
  --shelfy-logoBG: #{$shelfy-logoBG};
  --shelfy-current-app-version: #{$shelfy-current-app-version};
  --shelfy-btn-border-radius: 22px;
  --shelfy-icon-btn-size-small: 13px;
  --shelfy-icon-btn-size-big: 17px;
  --shelfy-designer-btn-bar-radius: 28px;

  // TODO: Refactor it finaly
  --ion-color-primary: #{$ion-color-primary};

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --ion-font-family: 'Montserrat-Regular', sans-serif !important;
  --shelfy-font: 'Montserrat', sans-serif;
  --shelfy-font-regular: 'Montserrat-Regular', sans-serif;
  --shelfy-font-medium: 'Montserrat-Medium', sans-serif;
  --shelfy-font-bold: 'Montserrat-Bold', sans-serif;
  --shelfy-font-semi-bold: 'Montserrat-SemiBold', sans-serif;
  --shelfy-font-filter-options: 'Montserrat-Bold', Arial, Helvetica, sans-serif;
  --app-size-mobile-max: $app-size-mobile-max;
}
